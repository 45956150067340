<template>
  <div class="card shadow-sm">
    <!-- <form id="formItems" class="needs-validation" novalidate> -->
    <div class="card-header">
      <h3 class="card-title" style="font-size: 20px; font-weight: bold">
        เลือกคลังสินค้า
      </h3>
      <div class="card-toolbar">
        <button
          class="btn btn-sm btn-light btn-primary me-2"
          style="font-size: 14px; font-weight: bold"
          @click="goToList()"
        >
          ตกลง
        </button>

        <button
          type="button"
          class="btn btn-sm btn-light me-2"
          style="font-size: 14px; font-weight: bold"
          @click="goToNew()"
        >
          ยกเลิก
        </button>
      </div>
    </div>
    <div class="card-body">
      <div class="row d-flex justify-content-center">
        <label for="validationTooltip01" class="required form-label col-sm-8"
          >คลัง</label
        >
        <div class="col-sm-8">
          <select
            class="form-select mb-4"
            v-model="form.moveToWarehouse"
            id="validationTooltip01"
            required
          >
            <option v-for="item in warehouses" :key="item" :value="item.id">
              {{ item.warehouse_code }} |
              {{ item.warehouse_name }}
            </option>
          </select>
          <div class="invalid-feedback text-end">กรุณาเลือก</div>
        </div>
      </div>
    </div>
    <!-- </form> -->
  </div>
</template>
<script>
import whApi from "@/api/warehouse/";

export default {
  setup() {
    document.title = "CHOMTHANA | Report";
  },
  data: () => ({
    form: {
      moveToWarehouse: "",
    },
    warehouses: [],
  }),

  async created() {
    await this.getWarehouse();
  },

  methods: {
    async getWarehouse() {
      let responseData = [];
      try {
        responseData = await whApi.warehouse.getAll();
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.warehouses = responseData.data;
        this.form.moveToWarehouse = responseData.data[0].id;
      }
    },
    async goToList() {
      await this.validateForm();
      await this.checkFormIsEmpty();
      if (this.isValid) {
        this.$router.push({
          path: "/warehouse/WH7/list",
          query: {
            warehouseId: this.form.moveToWarehouse,
          },
        });
      }
    },
    checkFormIsEmpty() {
      !this.form.moveToWarehouse
        ? (this.isValid = false)
        : (this.isValid = true);
    },
    validateForm() {
      let forms = document.querySelectorAll(".needs-validation");
      Array.prototype.slice.call(forms).forEach(function (form) {
        form.addEventListener(
          "submit",
          function (event) {
            event.preventDefault();
            if (!form.checkValidity()) {
              event.stopPropagation();
            }
            form.classList.add("was-validated");
          },
          false
        );
      });
    },
  },
};
</script>
